<template>
  <div>
    Dashboard Page
  </div>
</template>
<script>
  import config from '@/config';

  export default {
    components: {
    },
    data() {
      return {}
    },
    computed: {
    },
    methods: {
    },
    mounted() {
    },
    beforeDestroy() {
    }
  };
</script>
<style>
</style>
